import React from 'react'
import Layout from '../components/Layout'
import TitleArea from '../components/TitleArea'
import SEO from '../components/SEO'
import { Container, Columns, Column, Content, Section } from 'bloomer'

export default () => (
  <Layout>
    <SEO title="About" alias="About" />
    <TitleArea title="About Us" />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <p>
                Shady Lane Boarding Kennel is situated on 30 acres in a
                beautiful country park-like setting on a convenient main highway
                near Lucknow, Ont.
              </p>
              <p>
                The Hewitt family has owned and operated Shady Lane Boarding
                Kennel since 1999.
              </p>
              <p>
                We want you to feel comfortable on your vacation while you leave
                your pets with us. We are flexible towards our guests needs and
                your peace of mind is our priority.
              </p>
              <p>
                We provide bright, clean, modern accommodations, professional,
                flexible service and compassionate care for your special friend.
              </p>
              <p>
                You are invited to come anytime during{' '}
                <a
                  title="Hours and Directions"
                  href="https://shadylaneboardingkennel.com/hours-directions/"
                >
                  business hours
                </a>{' '}
                to view our facility and discuss your boarding needs.
              </p>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
